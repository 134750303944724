/**
 * La tarte au citron n'est pas prévue pour être utilisé comme un module node Js.
 * Dans son code source il n'y acucune notion d'export de module que ce soit celle de la spécification RequireJS ou CommonJS
 * Nous sommes contraint de le rendre modulable car nous travail dans une architecture modulaire chez AXN.
 * Pour cela nous plaçons ce package dans nos vendors des ressources et nous
 * modifions un peu leur code source et y apporter les méthodes d'export de module
 * en l'occurence celle de la spécification AMD (Asynchronous Module Definition) RequireJS car elle est la plus présente
 * dans notre projet.
 *
 */
window.tarteaucitron = require('../vendor/tarteaucitron/tarteaucitron').tarteaucitron


